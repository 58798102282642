<nb-card class="dialog" [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
        <table *ngIf="businesses.length" class="table table-hover table-sm">
            <tr>
                <th class="name"></th>
            </tr>
            <tr *ngFor="let business of businesses">
                <nb-user class="my-1" [name]="business.name" [title]="business.slug" [picture]="business.logoMiniSizes?.small" size="large" shape="semi-round"></nb-user>
            </tr>
        </table>
        <nb-alert *ngIf="!businesses?.length" status="info">
          El plan no tiene ningun comercio asociado.
        </nb-alert>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="close()" [status]="acceptButtonStatus" class="puntos-button filled float-right">Cerrar</button>
    </nb-card-footer>
</nb-card>