<nb-card class="dialog" [status]="status">
  <nb-card-header>Ingreso de número de teléfono</nb-card-header>
  <nb-card-body class="container">
    <p>{{ message }}</p>
    <ngx-intl-tel-input
      [enableAutoCountrySelect]="true"
      [enablePlaceholder]="true"
      [searchCountryFlag]="false"
      [selectFirstCountry]="false"
      [selectedCountryISO]="CountryISO.Argentina"
      [maxLength]="15"
      [phoneValidation]="true"
      searchCountryPlaceholder="Buscar país"
      name="phone"
      [(ngModel)]="phone"
    >
    </ngx-intl-tel-input>
  </nb-card-body>
  <nb-card-footer>
    <button
      nbButton
      (click)="cancel()"
      [status]="deleteButtonStatus"
      class="puntos-button outlined float-left"
    >
      Cancelar
    </button>
    <button
      nbButton
      (click)="accept()"
      [status]="acceptButtonStatus"
      class="puntos-button filled float-right"
    >
      Actualizar
    </button>
  </nb-card-footer>
</nb-card>
