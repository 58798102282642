import { PermissionBase } from './permission_base.model';

// * estos son los permisos de los posibles fraudes detectados.
export class PermissionPossibleFrauds extends PermissionBase {
  can_edit: boolean;
  can_delete: boolean;

  constructor() {
    super();
  }
}
