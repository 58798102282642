<nb-card class="dialog" [status]="status">
  <nb-card-header *ngIf="title">
    <div [innerHtml]="titleHtml"></div>
  </nb-card-header>
  <nb-card-body *ngIf="message">
    <div [innerHtml]="messageHtml"></div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="cancel()" class="float-left puntos-button muted">
      {{ cancelButtonText }}
    </button>
    <button
      nbButton
      (click)="accept()"
      class="float-right puntos-button filled"
    >
      {{ acceptButtonText }}
    </button>
  </nb-card-footer>
</nb-card>
