<nb-card class="dialog" [status]="status">
  <nb-card-header>Seleccione un tipo de integracion</nb-card-header>
  <nb-card-body>
    <nb-select id="inputType" [(ngModel)]="selectedType">
      <nb-option [value]="0">Ninguna</nb-option>
      <nb-option *ngFor="let type of types" [value]="type.key">{{
        type.value
      }}</nb-option>
    </nb-select>
  </nb-card-body>
  <nb-card-footer>
    <button
      nbButton
      (click)="cancel()"
      class="float-left puntos-button outlined"
    >
      Cancelar
    </button>
    <button
      nbButton
      [disabled]="selectedType === 0"
      (click)="accept()"
      class="float-right puntos-button filled"
    >
      Crear
    </button>
  </nb-card-footer>
</nb-card>
