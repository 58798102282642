import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Product } from '../_models/product.model';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { ProductOffer } from '../_models/product_offer.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ProductService {
    constructor(http) {
        this.http = http;
        this.reloadCategories = new EventEmitter();
        this.search = (params) => {
            return this.http.get('products/search', { params: params });
        };
    }
    one(id) {
        const product = new Product();
        if (id) {
            product.id = id;
        }
        return product;
    }
    get(product) {
        return this.http.get('products/' + product.id);
    }
    getProductOffer(productOfferId) {
        return this.http.get('products/offer/' + productOfferId).pipe(map((response) => {
            return new ProductOffer(response);
        }));
    }
    getAllProductsOffers(params) {
        return this.http.get(`products/offers`, { params }).pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((productOffer) => {
                return new ProductOffer(productOffer);
            });
            return new PaginationResult(pagination_result);
        }));
    }
    delete(product, business) {
        return this.http.delete(`products/${product.id}/business/${business.id}`);
    }
    getMarketplacetPaginationResult(params) {
        return this.http.get('products/marketplace', { params: params }).pipe(map((response) => {
            response.data = response.data.map((product) => {
                return new Product(product);
            });
            return new PaginationResult(response);
        }));
    }
    getPaginationResult(params) {
        return this.http.get('products', { params: params }).pipe(map((response) => {
            const products_pagination_result = response;
            products_pagination_result.data = response.data.map((product) => {
                return new Product(product);
            });
            return new PaginationResult(products_pagination_result);
        }));
    }
    getShopPaginationResult(params) {
        return this.http.get('products/shop', { params: params }).pipe(map((response) => {
            const products_pagination_result = response;
            products_pagination_result.data = response.data.map((product) => {
                return new Product(product);
            });
            return new PaginationResult(products_pagination_result);
        }));
    }
    all(params) {
        return this.http.get('products', { params: params }).pipe(map((response) => {
            return response.map((product) => new Product(product));
        }));
    }
    save(product, categoryChanges) {
        const method = product.id ? 'put' : 'post';
        let url = 'products';
        if (method === 'put') {
            url += '/' + product.id;
        }
        return this.http.request(method, url, {
            body: Object.assign({}, product, { categoryChanges }),
        });
    }
    addToPlan(productBusiness) {
        return this.http.request('post', 'products/shop', {
            body: productBusiness,
        });
    }
    removeToPlan(product, business) {
        return this.http.delete(`products/shop/${product.id}/business/${business.id}`);
    }
    patch(product, params) {
        return this.http.patch('products/' + product.id, params);
    }
    activateProductBusiness(product, business, active) {
        return this.http.patch(`products/shop/${product.id}/business/${business.id}/activate/${active}`, null);
    }
    patchProductBusiness(productId, businessId, params) {
        return this.http.patch(`products/shop/${productId}/business/${businessId}`, params);
    }
    getAllCategories() {
        return this.http.get('products/categories');
    }
    uploadCoupons(id, file) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post(`products/loadsheet/${id}/coupons`, formData);
    }
    getProductCoupon(coupon) {
        return this.http.get(`products/coupon/${coupon.id}`);
    }
}
ProductService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductService_Factory() { return new ProductService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductService, providedIn: "root" });
