/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./communication-detail.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@nebular/theme/index.ngfactory";
import * as i3 from "@nebular/theme";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./communication-detail.component";
var styles_CommunicationDetailComponent = [i0.styles];
var RenderType_CommunicationDetailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CommunicationDetailComponent, data: {} });
export { RenderType_CommunicationDetailComponent as RenderType_CommunicationDetailComponent };
export function View_CommunicationDetailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "nb-card", [["class", "dialog"]], [[2, "size-tiny", null], [2, "size-small", null], [2, "size-medium", null], [2, "size-large", null], [2, "size-giant", null], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null], [2, "accent", null], [2, "accent-primary", null], [2, "accent-info", null], [2, "accent-success", null], [2, "accent-warning", null], [2, "accent-danger", null]], null, null, i2.View_NbCardComponent_0, i2.RenderType_NbCardComponent)), i1.ɵdid(1, 49152, null, 0, i3.NbCardComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, 2, 6, "div", [["class", "notification-container"], ["style", "overflow: overlay;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "notification-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "notification-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, 2, 2, "button", [["class", "close close-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "nb-icon", [["class", "detail-icon"], ["icon", "Chevron_Left"]], [[8, "innerHTML", 1], [2, "status-primary", null], [2, "status-info", null], [2, "status-success", null], [2, "status-warning", null], [2, "status-danger", null]], null, null, i2.View_NbIconComponent_0, i2.RenderType_NbIconComponent)), i1.ɵdid(11, 638976, null, 0, i3.NbIconComponent, [i4.DomSanitizer, i3.NbIconLibraries, i1.ElementRef, i1.Renderer2], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_24 = "Chevron_Left"; _ck(_v, 11, 0, currVal_24); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).tiny; var currVal_1 = i1.ɵnov(_v, 1).small; var currVal_2 = i1.ɵnov(_v, 1).medium; var currVal_3 = i1.ɵnov(_v, 1).large; var currVal_4 = i1.ɵnov(_v, 1).giant; var currVal_5 = i1.ɵnov(_v, 1).primary; var currVal_6 = i1.ɵnov(_v, 1).info; var currVal_7 = i1.ɵnov(_v, 1).success; var currVal_8 = i1.ɵnov(_v, 1).warning; var currVal_9 = i1.ɵnov(_v, 1).danger; var currVal_10 = i1.ɵnov(_v, 1).hasAccent; var currVal_11 = i1.ɵnov(_v, 1).primaryAccent; var currVal_12 = i1.ɵnov(_v, 1).infoAccent; var currVal_13 = i1.ɵnov(_v, 1).successAccent; var currVal_14 = i1.ɵnov(_v, 1).warningAccent; var currVal_15 = i1.ɵnov(_v, 1).dangerAccent; _ck(_v, 0, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15]); var currVal_16 = _co.title; _ck(_v, 6, 0, currVal_16); var currVal_17 = _co.messageHtml; _ck(_v, 8, 0, currVal_17); var currVal_18 = i1.ɵnov(_v, 11).html; var currVal_19 = i1.ɵnov(_v, 11).primary; var currVal_20 = i1.ɵnov(_v, 11).info; var currVal_21 = i1.ɵnov(_v, 11).success; var currVal_22 = i1.ɵnov(_v, 11).warning; var currVal_23 = i1.ɵnov(_v, 11).danger; _ck(_v, 10, 0, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22, currVal_23); }); }
export function View_CommunicationDetailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "puntos-communication-detail", [], null, null, null, View_CommunicationDetailComponent_0, RenderType_CommunicationDetailComponent)), i1.ɵdid(1, 114688, null, 0, i5.CommunicationDetailComponent, [i3.NbDialogRef, i4.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CommunicationDetailComponentNgFactory = i1.ɵccf("puntos-communication-detail", i5.CommunicationDetailComponent, View_CommunicationDetailComponent_Host_0, { message: "message", title: "title" }, {}, []);
export { CommunicationDetailComponentNgFactory as CommunicationDetailComponentNgFactory };
