<nb-card class="dialog" [status]="status" style="max-width: 580px;">
  <nb-card-header style="display: flex; justify-content: space-between;">
    <div *ngIf="title != 'none'">{{ title }}</div>
    <button *ngIf="closeButton" (click)="close()" class="close close-button" ><nb-icon icon="Close_MD"></nb-icon></button>
  </nb-card-header>
    <nb-card-body>
      <div style="font-size: 17px; margin-top: 20px;">
        {{message}}<span *ngIf="videoURL" style="cursor: pointer; color: fuchsia; font-size: 1rem;" (click)="openVideoPopup()"> click aquí <fa-icon style="margin-left: 4px;" [icon]="icon"></fa-icon></span>
      </div>
    </nb-card-body>
    <nb-card-footer *ngIf="showButton">
        <button nbButton (click)="close()" [status]="buttonStatus" class="puntos-button filled btn-block">{{ buttonText }}</button>
    </nb-card-footer>
</nb-card>
