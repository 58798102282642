import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Business } from '../../_models/business.model';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-confirm-exchange-points-money',
  templateUrl: './confirm-exchange-points-money.component.html',
  styleUrls: ['./confirm-exchange-points-money.component.scss'],
})
export class ConfirmExchangePointsMoneyComponent implements OnInit {
  @Input() score: number;
  @Input() clientScore: number;
  @Input() business: Business;

  @Output() onAccept: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  constructor(private ref: NbDialogRef<ConfirmExchangePointsMoneyComponent>) {}

  ngOnInit() {}

  cancel() {
    this.ref.close(false);
    this.onCancel.emit(true);
  }

  accept() {
    this.onAccept.emit(true);
    this.ref.close(true);
  }
}
