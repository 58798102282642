import { environment } from '../../environments/environment';
import { Category } from './category.model';
import { Plan } from './plan.model';
import { BusinessBranch } from './business_branch.model';
import { Operator } from './operator.model';

type BusinessSections = 'Entregar Puntos' | 'Canjear puntos' | 'Premios' | 'Tienda de premios' | 'Movimientos' | 'Movimientos como proveedor' | 'Validar' | 'Operadores' | 'Permisos' | 'Integraciones' | 'Niveles' | 'Encuestas';
export class Business {
  id: number;
  name: string;
  slug: string;
  category: string;

  // Nuevos colores
  primary_color: string;
  primary_negative_color: string;
  secondary_color: string;
  secondary_negative_color: string;
  navbar_color: string;
  navbar_negative_color: string;

  color1: string;
  color2: string;
  color_menu: string;
  color_menu_text: string;
  color_money: string;
  color_money_text: string;
  color_category: string;
  color_group1: string;
  color_group2: string;
  color_group3: string;
  color_group3_secondary: string;

  logo: string = 'placeholder.png';
  logoSizes: any;

  logo_login: string;
  logoLoginSizes: any;

  logo_mini: string;
  logoMiniSizes: any;
  promo_mini_sizes: any;

  max_products: number;
  money_ratio: number;
  score_amount_ratio: number;
  money_commission: number;
  link: string;
  money_exchange_text: string;
  contact_text: string;
  whatsapp_message: string;
  twitter_url?: string;
  facebook_url?: string;
  linkedin_url?: string;
  instagram_url?: string;
  web_url?: string;
  custom_report_url: string;
  api_key: string;
  product_categories: Category[] = [];

  tag_reminder_days: number;
  score_reminder_days: number;
  birthday_reward_points: number;

  hide_global_points: boolean = true;

  send_birthday_reward: boolean = false;

  hired_business_plan: HiredBusinessPlan;

  sections: BusinessSections[] = [];

  admin: Operator = null;

  promo_popup_enabled_admin: boolean = false;
  promo_popup_enabled: boolean = false;
  promo_image: string;
  promo_button_text: string;
  promo_button_color: string;
  promo_redirect_url: string;

  login_with_dni_text: boolean = false;
  login_with_cuit_text: boolean = false;
  show_facebook_login: boolean = false;
  show_google_login: boolean = false;
  hide_register?: boolean = false;
  hide_movements_amount?: boolean = false;
  send_account_created?: boolean = true;
  avoid_sum_notification?: boolean = false;
  update_levels_score_ratio?: boolean = false;

  allow_min_amount: boolean = false;
  min_amount: number = 0;

  allow_min_branches_reads: boolean = false;
  min_branches_reads: number = 2;

  email_tag_notification_subject?: string;
  email_tag_notification_mainline?: string;
  tag_read_notification_subject?: string;
  tag_read_notification_mainline?: string;
  purchase_notification_subject?: string;
  purchase_notification_mainline?: string;
  unlocked_products_notification_subject?: string;
  unlocked_products_notification_mainline?: string;
  account_created_notification_subject?: string;
  account_created_notification_mainline?: string;
  levelup_notification_subject?: string;
  levelup_notification_mainline?: string;
  direct_tag_whatsapp_notification?: string;

  branches: BusinessBranch[] = [];

  allow_support_message: boolean = false;
  support_message: string;

  product_offer_max_value: number;

  allow_accumulate_points_rules: boolean = false;
  make_client_email_mandatory_for_exchanges: boolean = false;

  public constructor(init?: any) {
    Object.assign(this, init);
    this.setLogoSizes();
    this.setLogoMiniSizes();
    this.setLogoLoginSizes();
    this.setPromoImageMiniSizes();
    this.setLink();
    this.setBusinessTypes();
  }

  setBusinessTypes() {
    if (!this.sections.some(s => s === 'Validar')) this.sections.push('Validar');
    if (!this.sections.some(s => s === 'Premios')) this.sections.push('Premios');
    if (!this.sections.some(s => s === 'Integraciones')) this.sections.push('Integraciones');

    if (!!this.hired_business_plan && !!this.hired_business_plan.business_plan) {
      const plan = new Plan(this.hired_business_plan.business_plan);

      if (plan.canExchangePoints) {
        if (!this.sections.some(s => s === 'Canjear puntos')) this.sections.push('Canjear puntos');
      }

      if (plan.canOperatePoints) {
        if (!this.sections.some(s => s === 'Entregar Puntos')) this.sections.push('Entregar Puntos');
      }

      if (plan.canExchangePoints || plan.canOperatePoints) {
        if (!this.sections.some(s => s === 'Movimientos')) this.sections.push('Movimientos');
      }

      if (plan.allow_provider_mode) {
        if (!this.sections.some(s => s === 'Movimientos como proveedor')) this.sections.push('Movimientos como proveedor');
      }

      if (!plan.hide_products_shop) {
        if (!this.sections.some(s => s === 'Tienda de premios')) this.sections.push('Tienda de premios');
      }

      if (plan.allow_operators) {
        if (!this.sections.some(s => s === 'Operadores')) this.sections.push('Operadores');
      }

      if (plan.allow_permissions_groups) {
        if (!this.sections.some(s => s === 'Permisos')) this.sections.push('Permisos');
      }

      if (plan.allow_integrations) {
        if (!this.sections.some(s => s === 'Integraciones')) this.sections.push('Integraciones');
      }

      if (plan.allow_levels) {
        if (!this.sections.some(s => s === 'Niveles')) this.sections.push('Niveles');
      }

      if (plan.allow_surveys) {
        if (!this.sections.some(s => s === 'Encuestas')) this.sections.push('Encuestas');
      }
    }
  }

  get isOnlyProvider() {
    if (!!this.hired_business_plan && !!this.hired_business_plan.business_plan) {
      const plan = new Plan(this.hired_business_plan.business_plan);
      return plan.allow_provider_mode && !plan.canOperatePoints && !plan.canExchangePoints;
    }
    return false;
  }

  setLogoSizes(): any {

    const sizes = {
      'header': 'AUTOx96',
      'thumb': '48x48_max',
      'small': '200x200_max',
      'standard': '400x300_max',
    };

    this.logoSizes = {};

    Object.keys(sizes).forEach((size) => {
      this.logoSizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + (this.logo || 'placeholder.png') ;
    });

  }

  setLogoMiniSizes(): any {

    const sizes = {
      'header': 'AUTOx96',
      'thumb': '48x48_max',
      'small': '200x200_max',
      'standard': '400x300_max',
    };

    this.logoMiniSizes = {};

    if (this.logo_mini) {
      Object.keys(sizes).forEach((size) => {
        this.logoMiniSizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + this.logo_mini ;
      });
    }

  }

  setLogoLoginSizes(): any {

    const sizes = {
      'header': 'AUTOx96',
      'thumb': '48x48_max',
      'small': '200x200_max',
      'standard': '400x300_max',
    };

    this.logoLoginSizes = {};

    if (this.logo_login) {
      Object.keys(sizes).forEach((size) => {
        this.logoLoginSizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + this.logo_login ;
      });
    }

  }

  setPromoImageMiniSizes() {

    const sizes = {
      'header': 'AUTOx96',
      'thumb': '48x48_max',
      'small': '200x200_max',
      'standard': '400x300_max',
    };

    this.promo_mini_sizes = {};

    // TODO: Cambiar al bucket puntos-businesses-promos y sacarlo del de logos
    if (this.promo_image) {
      Object.keys(sizes).forEach((size) => {
        this.promo_mini_sizes[size] = 'https://logos.tiendadepuntos.com/' + sizes[size] + '/' + this.promo_image ;
      });
    }
  }

  setLink() {
    if (!this.slug) {
      return this.link = environment.frontendBaseUrl;
    }
    const parts = environment.frontendBaseUrl.split('//');
    return this.link = parts[0] + '//' + this.slug + '.' + parts[1];
  }

}

export class HiredBusinessPlan {
  public constructor(init?: any) {
    Object.assign(this, init);
  }

  suspended: boolean = true;
  business_plan: Plan;
  business_plan_id: number;
  expiration_date: string;
}
