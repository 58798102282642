import { PermissionBase } from './permission_base.model';

// * estos son los permisos de los detectores de fraudes asociados de un negocio.
// * es parte de las configuraciones de los detectores.
export class PermissionPointRules extends PermissionBase {
  can_create: boolean;
  can_edit: boolean;
  can_delete: boolean;
  can_change_priority: boolean;

  constructor() {
    super();
  }
}
