import { NB_AUTH_OPTIONS, NbAuthResult, NbAuthService, NbLoginComponent } from '@nebular/auth';
import { ChangeDetectorRef, Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Business } from '../../_models/business.model';
import { UserService } from '../../_services/user.service';
import { BusinessService } from '../../_services/business.service';
import { ClientService } from '../../_services/client.service';
import { OperatorService } from '../../_services/operator.service';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss', '../auth.component.scss'],
})
export class LoginComponent
  extends NbLoginComponent
  implements OnInit, OnDestroy {
  then: string;
  socialAlive = true;
  showNativeLogin = false;
  hideRegister = false;
  business: Business;

  isTuNegocio: boolean = false;
  isSuperAdmin: boolean = false;

  loginText: string = 'Ingresá con tu email y contraseña';
  loginWithFacebookOrGoogleEnabled: boolean = false;

  isPasswordVisible: boolean = false;
  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected http: HttpClient,
    protected userService: UserService,
    protected operatorService: OperatorService,
    protected clientService: ClientService,
    protected businessService: BusinessService,
  ) {
    super(service, options, cd, router);
    this.then = this.route.snapshot.params.then;
    const status = this.route.snapshot.params.status;

    if (status === 'verification_pending') {
      this.showNativeLogin = true;
      this.messages.push(
        'Te hemos enviado un correo con las instrucciones para continuar.',
      );
    } else if (status === 'verified') {
      this.showNativeLogin = true;
      this.messages.push(
        'Activación exitosa. Por favor ingresá con tus datos.',
      );
    } else if (status === 'points-loaded') {
      this.showNativeLogin = true;
      this.messages.push(
        'Activación exitosa y puntos agregados a la cuenta. Por favor ingresá con tus datos.',
      );
    } else if (status === 'business_registered') {
      this.messages.push(
        '¡Listo! Ahora por favor ingresá con los datos que acabás de ingresar.',
      );
    }
  }

  ngOnInit() {
    this.isTuNegocio = this.businessService.getSubdomain() === 'tunegocio';
    this.isSuperAdmin = this.businessService.getSubdomain() === 'mi';

    this.route.data.subscribe((data) => {
      this.business = data.business;

      if (this.business.hide_register) {
        this.showNativeLogin = true;
        this.hideRegister = true;
      }

      this.loginText =
        this.business.show_google_login || this.business.show_facebook_login
          ? 'O '
          : '';
      this.loginWithFacebookOrGoogleEnabled =
        this.business.show_google_login || this.business.show_facebook_login;
      if (this.business.login_with_dni_text) {
        this.loginText += 'Ingresá con tu DNI y Contraseña';
      } else if (this.business.login_with_cuit_text) {
        this.loginText += 'Ingresá con tu CUIT y Contraseña';
      } else {
        this.loginText += 'Ingresá con tu E-mail y Contraseña';
      }
    });
    this.user.tos = true;
  }

  demoCommerceLogin() {
    this.user.email = 'tunegocio@tiendadepuntos.com';
    this.user.password = 'demo1234';
    this.login();
  }

  demoClientLogin() {
    this.user.email = 'clientedemo@tiendadepuntos.com';
    this.user.password = 'demo1234';
    this.login();
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe((result) => {
      /* if ( this.isTuNegocio() ) {
          localStorage.setItem('status_login', 'AFTER_LOGIN');
        } */
      this.submitted = false;
      this.operatorService.clear();
      this.clientService.clear();
      this.userService.clear();
      this.businessService.clear();
      if (result.isSuccess()) {
        this.messages = result.getMessages();
        if (this.then) {
          this.router.navigate([this.then], {
            queryParams: { welcome_popup: 'show' },
          });
        } else {
          this.router.navigate(['/'], {
            queryParams: { welcome_popup: 'show' },
          });
        }
      } else {
        switch (result.getErrors()[0]) {
          case 'invalid_credentials':
            this.errors.push(
              'El usuario o la contraseña ingresados no son válidos',
            );
            break;
          case 'suspended_account':
            this.errors.push('Cuenta suspendida');
            break;
          default:
            this.errors = result.getErrors();
            break;
        }
      }
      this.cd.detectChanges();
    });
  }

  socialLogin(social) {
    localStorage.setItem(
      'social_redirect_uri',
      this.route.snapshot.params.then,
    );

    this.service
      .authenticate(social)
      .pipe(takeWhile(() => this.socialAlive))
      .subscribe((authResult: NbAuthResult) => {});
  }

  ngOnDestroy(): void {
    this.socialAlive = false;
  }
}
