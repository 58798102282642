<nb-card class="dialog">
  <nb-card-body>
    <p style="font-size: 15px; text-align: center">
      ¿Seguro que quieres canjear tus puntos por Dinero Real?
    </p>
    <p style="font-size: 22px; text-align: center">
      Vas a utilizar
      <b
        style="
          color: var(--secondary-color);
          font-size: 22px;
          text-align: center;
        "
      >
        <nb-icon icon="logo" pack="puntos" style="line-height: 0.8; margin: 0 4px;"></nb-icon>
        {{ score }} puntos
      </b>
    </p>
    <p style="font-size: 15px; text-align: center; margin-botton: 0">
      Tenes
      <b
        style="
          color: var(--secondary-color);
          font-size: 15px;
          text-align: center;
        "
      >
        <nb-icon icon="logo" pack="puntos"  style="line-height: 0.6; margin: 0 4px;"></nb-icon>
        {{ clientScore }} puntos
      </b>
    </p>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton (click)="cancel()" class="float-left puntos-button muted">
      Volver
    </button>
    <button
      nbButton
      (click)="accept()"
      class="float-right puntos-button filled"
    >
      Canjear
    </button>
  </nb-card-footer>
</nb-card>
