<div *ngIf="isTuNegocio" class="demo">
  <p style="font-size: 17px">
    Ingresa a la demo seleccionando el perfil de usuario
  </p>
  <div class="buttons">
    <button
      class="commerce-button"
      (click)="demoCommerceLogin()"
      style="font-weight: 900"
    >
      Ingresar como comercio
    </button>
    <button
      class="client-button"
      (click)="demoClientLogin()"
      style="font-weight: 900"
    >
      Ingresar como tu cliente
    </button>
  </div>
  <img
    src="/assets/images/Ilustracion-principal-01-01.jpg"
    style="max-width: 100%"
  />
</div>

<div *ngIf="!isTuNegocio" class="prod">
  <div class="go-back-header" *ngIf="!hideRegister && showNativeLogin">
    <a
      [routerLink]=""
      (click)="showNativeLogin = 0"
      class="go-back float-left back"
    >
      <nb-icon icon="Chevron_Left"></nb-icon> Volver
    </a>
  </div>

  <h1 *ngIf="!showNativeLogin" class="margin-top-4rem">Ingresá en tu cuenta</h1>
  <h1 *ngIf="showNativeLogin" class="margin-top-4rem">Iniciar sesión</h1>

  <h2 id="title" class="title-description" *ngIf="business?.id">
    Sumá puntos y alcanzá increibles premios en
    <strong>{{ business?.name }}</strong
    >.
  </h2>
  <h2
    id="title"
    class="title-description"
    *ngIf="!business?.id && !showNativeLogin"
  >
    O registrate para empezar a utilizar <strong>Tienda de Puntos</strong>.
  </h2>

  <div class="logo-container" *ngIf="!showNativeLogin">
    <img *ngIf="!business?.id" src="assets/images/logo_new.png" alt="" />
    <img
      *ngIf="!!business?.id"
      id="logo-login"
      src="{{ business?.logoLoginSizes.standard }}"
      alt=""
    />
  </div>

  <section
    *ngIf="socialLinks && socialLinks.length > 0"
    class="links"
    aria-label="Social sign in"
  >
    <div *ngIf="!showNativeLogin">
      <button
        nbButton
        fullWidth
        tabindex="4"
        class="puntos-button filled"
        (click)="showNativeLogin = 1"
      >
        Iniciar sesión
      </button>
    </div>
    <div *ngIf="!showNativeLogin">
      <button
        nbButton
        fullWidth
        tabindex="3"
        class="puntos-button outlined"
        [routerLink]="['../register', then ? { then: then } : {}]"
      >
        Registrarse
      </button>
    </div>
  </section>

  <div class="auth-form" *ngIf="showNativeLogin">
    <nb-alert
      *ngIf="showMessages.error && errors?.length && !submitted"
      outline="danger"
      role="alert"
    >
      <p class="alert-title"><b>¡Upa!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
      </ul>
    </nb-alert>

    <nb-alert
      *ngIf="showMessages.success && messages?.length && !submitted"
      outline="success"
      role="alert"
    >
      <p class="alert-title"><b>¡Bien!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">
          {{ message }}
        </li>
      </ul>
    </nb-alert>

    <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">
      <div class="form-control-group">
        <input
          nbInput
          tabindex="1"
          fullWidth
          [(ngModel)]="user.email"
          #email="ngModel"
          name="email"
          id="input-email"
          placeholder="{{
            business?.login_with_dni_text
              ? 'DNI'
              : business?.login_with_cuit_text
              ? 'CUIT'
              : 'Email'
          }}"
          autocomplete="email"
          fieldSize="large"
          autofocus
          [status]="email.dirty ? (email.invalid ? 'danger' : 'success') : ''"
          [required]="getConfigValue('forms.validation.email.required')"
          [attr.aria-invalid]="email.invalid && email.touched ? true : null"
          [class.is-invalid]="email.invalid && email.touched ? true : null"
        />
        <ng-container *ngIf="email.invalid && email.touched">
          <p class="caption status-danger" *ngIf="email.errors?.required">
            <i class="fas fa-times-circle"></i>
            ¡Olvidaste ingresar tu
            {{
              business?.login_with_dni_text
                ? "DNI"
                : business?.login_with_cuit_text
                ? "CUIT"
                : "Email"
            }}!
          </p>
          <p class="caption status-danger" *ngIf="email.errors?.pattern">
            <i class="fas fa-times-circle"></i>
            Este no parece un
            {{
              business?.login_with_dni_text
                ? "DNI"
                : business?.login_with_cuit_text
                ? "CUIT"
                : "Email"
            }}
            válido.
          </p>
        </ng-container>
      </div>

      <div class="form-control-group">
        <div class="input-with-icon">
          <input
          nbInput
          tabindex="2"
          fullWidth
          [(ngModel)]="user.password"
          #password="ngModel"
          name="password"
          [type]="isPasswordVisible ? 'text' : 'password'"
          id="input-password"
          placeholder="Contraseña"
          autocomplete="current-password"
          fieldSize="large"
          [status]="
            password.dirty ? (password.invalid ? 'danger' : 'success') : ''
          "
          [required]="getConfigValue('forms.validation.password.required')"
          [minlength]="getConfigValue('forms.validation.password.minLength')"
          [maxlength]="getConfigValue('forms.validation.password.maxLength')"
          [attr.aria-invalid]="
            password.invalid && password.touched ? true : null
          "
        />
        <nb-icon icon="Show" *ngIf="isPasswordVisible" (click)="togglePasswordVisibility()"> </nb-icon>
        <nb-icon icon="Hide" *ngIf="!isPasswordVisible" (click)="togglePasswordVisibility()"> </nb-icon>
        </div>

        <ng-container *ngIf="password.invalid && password.touched">
          <p class="caption status-danger" *ngIf="password.errors?.required">
            <i class="fas fa-times-circle"></i>
            ¡Olvidaste ingresar tu contraseña!
          </p>
          <p
            class="caption status-danger"
            *ngIf="password.errors?.minlength || password.errors?.maxlength"
          >
            <i class="fas fa-times-circle"></i>
            La contraseña debe tener más de
            {{ getConfigValue("forms.validation.password.minLength") }}
            caracteres.
          </p>
        </ng-container>

        <span
          class="forgot-password"
          *ngIf="
            !business?.login_with_dni_text && !business?.login_with_cuit_text
          "
        >
          <a
            class="forgot-password-link caption-2"
            routerLink="../request-password"
            >Olvidé mi contraseña</a
          >
        </span>
      </div>

      <button
        class="entry-button"
        nbButton
        tabindex="3"
        fullWidth
        size="large"
        [disabled]="submitted || !form.valid"
        [class.btn-pulse]="submitted"
      >
        <div class="text">Ingresar</div>
      </button>
    </form>
  </div>

  <div *ngIf="loginWithFacebookOrGoogleEnabled" class="line-text">
    <span>O ingresá con</span>
  </div>

  <div *ngIf="loginWithFacebookOrGoogleEnabled" class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a
        *ngIf="
          socialLink.url &&
          (isSuperAdmin ||
            (socialLink.title === 'Facebook' &&
              business?.show_facebook_login) ||
            (socialLink.title === 'Google' && business?.show_google_login))
        "
        [attr.target]="socialLink.target"
        [attr.class]="socialLink.icon"
        [class.with-icon]="socialLink.icon"
        (click)="socialLogin(socialLink.title.toLowerCase())"
      >
        <nb-icon
          *ngIf="socialLink.icon; else title"
          [icon]="socialLink.icon"
          [pack]="socialLink.iconPack || 'fa'"
          class="fa-2x"
        ></nb-icon>
      </a>
    </ng-container>
  </div>

  <div class="not-account">
    <p>
      ¿No tenés cuenta?
      <strong [routerLink]="['../register', then ? { then: then } : {}]"
        >Registrarse</strong
      >
    </p>
  </div>

  <div class="terms-and-conditions" *ngIf="!showNativeLogin">
    Al ingresar con Facebook o Google estás aceptando los
    <a *ngIf="!businessUser" href="https://www.tiendadepuntos.com/tyc-usuarios"
      >términos y condiciones</a
    >
    <a *ngIf="businessUser" href="https://www.tiendadepuntos.com/tyc-comercios"
      >términos y condiciones para comercios</a
    >
    y la
    <a href="https://www.tiendadepuntos.com/politicas-usuarios"
      >política de privacidad</a
    >.
  </div>
</div>
