import { Injectable } from '@angular/core';
import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user.model';
import { ReplaySubject, Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {

  user: User|null = new User;
  private _user = new ReplaySubject<User>(1);
  user$ = this._user.asObservable();

  constructor(private authService: NbAuthService,
              private http: HttpClient,
              private router: Router) {

    this.authService
      .onAuthenticationChange()
      .subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.user = null;
          this._user.next(null);
          return;
        }

        this.reloadUser().subscribe((user) => {
          const isClientPreview = sessionStorage.getItem('isClientPreview');
          if (isClientPreview === 'true') {
            this.router.navigate(['/app/score']);
          } else if (isClientPreview === 'false') {
            sessionStorage.removeItem('isClientPreview');
            this.router.navigate(['/business/tags/operate']);
            location.reload();
          }
        });
      });

  }

  one(id) {
    return new User({ id: +id });
  }

  get(user: User) {
    return this.http.get(`users/${user.id}`)
      .pipe(map((response) => new User(response)));
  }

  getUser(force = false) {
    if (localStorage.getItem('user') === null || force || sessionStorage.getItem('isClientPreview')) {
      return this.reloadUser();
    } else {
      this.user = new User(JSON.parse(localStorage.getItem('user')));
      this.user.role = sessionStorage.getItem('isClientPreview') ? 'user' : this.user.role;
      this._user.next(this.user);
      return Observable.of(this.user);
    }
  }

  reloadUser() {
    return this.http.get('user')
      .pipe(map((response: any) => {
        if (response.success) {
          return new User({
            ...response.data,
            role: sessionStorage.getItem('isClientPreview') ? 'user' : response.data.role,
          });
        } else {
          this.router.navigate(['auth/logout']);
          return;
        }
      }))
      .pipe(map((user) => {
        this.user = user;
        this._user.next(this.user);
        return this.user;
      }));
  }

  retrieveToken(params: any) {
    return this.http.get('auth/validateToken', { params: params })
      .pipe(map((response: any) => {
        response.token = params.token;
        return response;
      }));
  }

  current() {
    return this.user$
      .filter(user => user !== null);
  }

  clear() {
    this.user = null;
    this._user.next(this.user);
    localStorage.removeItem('user');
  }

  requestResetPassword(data: any) {
    return this.http.post('oauth/password/email', data);
  }

  // Todo: Ver si esto se esta usando
  /*checkPassword(token) {
    return this.http.post('oauth/password/reset', { token });
  }

  resetPassword(token, newPassword) {
    return this.http.post('oauth/password/reset', { token });
  }*/

  resetPassword(password, password_confirmation) {
    return this.http.put('users/password', { password, password_confirmation });
  }

  resendVerification(id, business_id) {
    return this.http.post(`users/resendVerification/${id}`, { id, business_id });
  }

  activateEmail(operator: User) {
    return this.http.post(`users/activateEmail/${operator.id}`, {});
  }

  save(user: User, send_notification = false) {
    const method = user.id ? 'put' : 'post';
    let url = 'users';

    if (method === 'put') {
      url += '/' + user.id;
    }

    return this.http.request(method, url, { body: { ...user, send_notification } });
  }

  search = (q, m, b?) => {
    const params: any = {q, m};
    if (b) {
      params.b = b;
    }
    return this.http.get('users/search', { params: params } );
  }

  unsubscribe(email, type) {
    return this.http.get('notifications/unsubscribe', { params: { email, type } } );
  }

  loadUsers(id: number, file: File, sendNotification: boolean = false) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('sendNotification', sendNotification.toString());
    return this.http.post(`users/loadsheet/${id}`, formData);
  }

  updateEmail(userId: number, email: string) {
    return this.http.put(`users/${userId}/email`, { email });
  }

  updateDni(userId: number, dni: string) {
    return this.http.put(`users/${userId}/dni`, { dni });
  }
}
