import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'puntos-show-qr',
  templateUrl: './show-qr.component.html',
  styleUrls: ['./show-qr.component.scss'],
})
export class ShowQrComponent implements OnInit {

  @Input() qr: string = '';
  @Input() downloadCode: string = '';
  @Input() code: string = '';
  @Input() link: string = '';
  @Input() title: string = '';

  @Input() status: string;

  @Input() acceptButtonStatus = 'primary';

  @Output() onDownload = new EventEmitter<any>();


  constructor(private ref: NbDialogRef<ShowQrComponent>) { }

  ngOnInit() { }

  download() {
    this.onDownload.emit();
  }

  close() {
    this.ref.close(false);
  }

  openLink(url: string): void {
    window.open(url, '_blank');
  }

  copyLink() {
    const linkInput = document.createElement('input');
    linkInput.value = this.link;
    document.body.appendChild(linkInput);
    linkInput.select();
    document.execCommand('copy');
    document.body.removeChild(linkInput);
  }

}
