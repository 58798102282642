<form [formGroup]="form" (submit)="save()" name="form" novalidate>
  <nb-card class="dialog" [status]="status">
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div class="row form-group">
        <label for="inputPassword" class="label col-sm-3 col-form-label">Nueva contraseña</label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="password" id="inputPassword" name="password" class="form-control"
            formControlName="password">
          <ng-container *ngIf="controls.password.invalid && controls.password.touched">
            <p class="caption status-danger" *ngIf="controls.password.errors?.required">
              <i class="fas fa-times-circle"></i>
              Necesitamos tu nueva contraseña.
            </p>
            <p class="caption status-danger" *ngIf="controls.password.errors?.minlength">
              <i class="fas fa-times-circle"></i>
              La contraseña debe tener
              más de {{ controls.password.errors.minlength.requiredLength }}
              caracteres.
            </p>
            <p class="caption status-danger" *ngIf="controls.password.errors?.maxlength">
              <i class="fas fa-times-circle"></i>
              La contraseña no debe tener
              más de {{ controls.password.errors.maxlength.requiredLength }}
              caracteres.
            </p>
          </ng-container>
        </div>
      </div>

      <div class="row form-group">
        <label for="inputPassword2" class="label col-sm-3 col-form-label">Confirmá tu nueva contraseña</label>
        <div class="col-sm-9">
          <input nbInput fullWidth type="password" id="inputPassword2" name="confirmPassword" class="form-control"
            formControlName="confirmPassword">
          <ng-container *ngIf="controls.confirmPassword.touched">
            <p class="caption status-danger" *ngIf="controls.confirmPassword.errors?.required">
              <i class="fas fa-times-circle"></i>
              Necesitamos que confirmes tu contraseña.
            </p>
            <p class="caption status-danger"
              *ngIf="controls.password.value != controls.confirmPassword.value && !controls.confirmPassword.errors?.required">
              <i class="fas fa-times-circle"></i>
              Las contraseñas no coinciden.
            </p>
          </ng-container>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <a href="" (click)="cancel(); false" class="float-left" style="padding: 0.6875rem 1.125rem">{{ cancelButtonText }}</a>
      <button nbButton [status]="acceptButtonStatus" class="puntos-button filled float-right" [nbSpinner]="saving"
        [disabled]="saving || (!form.valid || controls.password.value != controls.confirmPassword.value)">{{
        acceptButtonText }}</button>
    </nb-card-footer>
  </nb-card>
</form>