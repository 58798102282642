<nb-card class="dialog">
  <div class="header-content">
    <nb-icon icon="Building_01"></nb-icon>
    <h2>Elegí la sucursal que quieras operar</h2>
    <p>
      Podrás cambiar la sucursal dentro del panel administrador, cliqueando al
      lado del botón de log out.
    </p>
  </div>

  <nb-radio-group [(ngModel)]="selectedBranch"> </nb-radio-group>

  <div
    class="branch-option"
    *ngFor="let operatorBranch of operatorBranches"
    [class.checked]="selectedBranch.id === operatorBranch.id"
    (click)="changeOperatorBranch(operatorBranch)"
  >
    <p [class.checked]="selectedBranch.id === operatorBranch.id">
      {{ operatorBranch.branch.name }}
    </p>
    <div
      class="radio-button"
      [class.checked]="selectedBranch.id === operatorBranch.id"
    >
      <nb-icon *ngIf="selectedBranch.id === operatorBranch.id" icon="Check"></nb-icon>
    </div>
  </div>

  <button nbButton class="puntos-button filled mt-3" (click)="confirmSelection()">
    Confirmar
  </button>

</nb-card>
