import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Business } from '../_models/business.model';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { Read } from '../_models/read.model';
import { Purchase } from '../_models/purchase.model';
import { HistoryService } from './history.service';
import { PaginationResult } from '../shared/pagination-result/pagination-result.model';
import { environment } from '../../environments/environment';
import { BusinessBranch } from '../_models/business_branch.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
import * as i3 from "./history.service";
export class BusinessService {
    constructor(http, document, historyService) {
        this.http = http;
        this.document = document;
        this.historyService = historyService;
        this.business = new Business();
        this._business = new ReplaySubject(1);
        this.business$ = this._business.asObservable();
        this.subdomain = this.getSubdomain();
        this.localStorageKey = `business_${this.subdomain}`;
        this.getCurrent();
    }
    setBusiness(business) {
        this.business = business;
        this._business.next(this.business);
        localStorage.setItem(this.localStorageKey, JSON.stringify(business));
    }
    one(id) {
        const business = new Business();
        if (id) {
            business.id = id;
        }
        return business;
    }
    get(business) {
        return this.http.get('businesses/' + business.id).pipe(map((response) => {
            return new Business(response);
        }));
    }
    getBySlug(slug) {
        return this.http.get('businesses/slug/' + slug).pipe(map((response) => {
            return new Business(response);
        }));
    }
    getPaginationResult(page, per_page, name) {
        const params = { page: page, per_page: per_page };
        if (name) {
            params.name = name;
        }
        return this.http.get('businesses', { params: params }).pipe(map((response) => {
            const pagination_result = response;
            pagination_result.data = pagination_result.data.map((business) => {
                return new Business(business);
            });
            return new PaginationResult(pagination_result);
        }));
    }
    all(params) {
        return this.http.get('businesses', { params: params }).pipe(map((response) => {
            return response.map((business) => {
                return new Business(business);
            });
        }));
    }
    save(business) {
        const method = business.id ? 'put' : 'post';
        let url = 'businesses';
        if (method === 'put') {
            url += '/' + business.id;
        }
        return this.http.request(method, url, { body: business });
    }
    delete(business) {
        return this.http.delete(`businesses/${business.id}`);
    }
    resendVerification(business) {
        return this.http.put(`businesses/${business.id}/activation`, business);
    }
    getBusinessFromStorage() {
        return new Business(JSON.parse(localStorage.getItem(this.localStorageKey)));
    }
    getCurrent(force = false) {
        if (this.subdomain === 'mi') {
            this.business = new Business();
            this._business.next(this.business);
            return Observable.of(this.business);
        }
        if (this.subdomain === 'www') {
            this.document.location.href = this.document.location.href.replace('www.', '');
        }
        if (localStorage.getItem(this.localStorageKey) === null || force) {
            return this.reloadBusiness().pipe(catchError((err) => {
                if (err.status === 404) {
                    this.document.location.href = '/notfound.html';
                }
                return throwError('Invalid subdomain!');
            }));
        }
        else {
            this.business = new Business(JSON.parse(localStorage.getItem(this.localStorageKey)));
            this._business.next(this.business);
            return Observable.of(this.business);
        }
    }
    reloadBusiness() {
        if (!this.getSubdomain()) {
            return Observable.of(this.one());
        }
        return this.getBySlug(this.getSubdomain()).pipe(tap((business) => localStorage.setItem(this.localStorageKey, JSON.stringify(business))), map((business) => {
            this.business = business;
            this._business.next(this.business);
            return this.business;
        }));
    }
    current() {
        return this.getCurrent();
    }
    clear() {
        if (this.business) {
            localStorage.removeItem(`business_${this.business.slug}`);
        }
        this.business = null;
        this._business.next(this.business);
    }
    getSubdomain() {
        const subdomain = window.location.hostname
            .replace(environment.frontendBaseUrl.split('//')[1], '')
            .split('.');
        if (subdomain.length > 1) {
            return subdomain[0];
        }
        return null;
    }
    getLink(business) {
        return business.link;
    }
    getCategories() {
        return this.http.get(`businesses/categories`).pipe(map((categories) => categories.map(function (category) {
            return category;
        })));
    }
    getPlans() {
        return this.http.get(`business-plans`).pipe(map((plans) => plans.map(function (plan) {
            return plan;
        })));
    }
    getHistory(business, params = {}) {
        params = { params };
        if (business.id) {
            params.business = business.id;
        }
        return this.historyService
            .get(params)
            .pipe(map((events) => events.map((event) => event.type === 'purchase' ? new Purchase(event) : new Read(event))));
    }
    getBranches(business) {
        const params = new HttpParams();
        if (business) {
            params.set('business_id', business.id.toString());
        }
        return this.http.get(`branches`).pipe(map((response) => {
            return response;
        }));
    }
    suspended(status, business_id) {
        const suspended = status;
        return this.http.put(`businesses/${business_id}/hired-plan/suspended`, {
            suspended,
        });
    }
    renew(business_id) {
        return this.http.put(`businesses/${business_id}/hired-plan/renew`, {});
    }
    getAllBranches() {
        return this.http.get(`businesses/branches`).pipe(map((response) => {
            const data = response.map((branch) => {
                return new BusinessBranch(branch.name, branch.id, branch.enabled);
            });
            return data;
        }));
    }
    changeUserRole(business, user, role) {
        return this.http.put(`businesses/${business.id}/user/${user.id}/role`, {
            role,
        });
    }
}
BusinessService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BusinessService_Factory() { return new BusinessService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.DOCUMENT), i0.ɵɵinject(i3.HistoryService)); }, token: BusinessService, providedIn: "root" });
