import { AbstractControl, ValidationErrors } from '@angular/forms';

export function MaxDecimalsValidator(decimalPlaces: number) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null; // Si no hay valor, no hay error

    const value = control.value.toString();
    const decimalPart = value.split('.')[1];

    if (decimalPart && decimalPart.length > decimalPlaces) {
      return { 'maxDecimals': { requiredDecimals: decimalPlaces, actualDecimals: decimalPart.length } };
    }

    return null;
  };
}
