import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  NbDialogService,
  NbMediaBreakpointsService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme';
import { GlobalSpinnerService } from '../../../_services/global-spinner.service';
import { environment } from '../../../../environments/environment';
import { UserService } from '../../../_services/user.service';
import { User } from '../../../_models/user.model';
import { MenuItem } from '../../../pages/pages-menu';
import { Business } from '../../../_models/business.model';
import { BusinessService } from '../../../_services/business.service';
import { Router } from '@angular/router';
import { Client } from '../../../_models/client.model';
import { ClientService } from '../../../_services/client.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Operator } from '../../../_models/operator.model';
import { OperatorService } from '../../../_services/operator.service';
import { OperatorBranchSelectorComponent } from '../../../_dialogs/operator-branch-selector/operator-branch-selector.component';
import { OperatorBranch } from '../../../_models/operator_branch.model';
import { darkenHexColor, hexToRgba } from '../../../@core/utils/color-utils';

@Component({
  selector: 'puntos-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  templateUrl: './one-column.layout.html',
})
export class OneColumnLayoutComponent implements OnInit, OnDestroy {
  socialMenu: MenuItem[] = [];

  business: Business;
  showSidebarBackdrop: boolean;
  spinner: any;
  isBusiness: boolean = false;
  showHeader: boolean = false;

  @Input() username: string = '';
  @Input() user: User = null;
  @Input() client: Client = null;
  @Input() operator: Operator = null;

  constructor(
    private businessService: BusinessService,
    private userService: UserService,
    private clientService: ClientService,
    private sidebarService: NbSidebarService,
    private spinnerService: GlobalSpinnerService,
    private router: Router,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private operatorService: OperatorService,
    private dialogService: NbDialogService,
  ) {
    this.businessService.current().subscribe((business) => {
      this.business = business;
      document.documentElement.style.setProperty('--primary-color', this.business.primary_color || '#5b53f0');
      document.documentElement.style.setProperty('--primary-light-color', hexToRgba(this.business.primary_color || '#5b53f0', 0.1));
      document.documentElement.style.setProperty('--primary-dark-color', darkenHexColor(this.business.primary_color || '#5b53f0', 20));
      document.documentElement.style.setProperty('--primary-negative-color', this.business.primary_negative_color || '#ffffff');
      document.documentElement.style.setProperty('--secondary-color', this.business.secondary_color || '#009EF3');
      document.documentElement.style.setProperty('--secondary-negative-color', this.business.secondary_negative_color || '#ffffff');
      document.documentElement.style.setProperty('--navbar-color', this.business.navbar_color || '#5b53f0');
      document.documentElement.style.setProperty('--navbar-negative-color', this.business.navbar_negative_color || '#ffffff');
    });

    this.sidebarService
      .onCollapse()
      .subscribe(() => (this.showSidebarBackdrop = false));
    this.sidebarService
      .onExpand()
      .subscribe(() => (this.showSidebarBackdrop = true));
    this.sidebarService
      .onToggle()
      .subscribe(() => (this.showSidebarBackdrop = !this.showSidebarBackdrop));

    this.spinner = this.spinnerService._spinner.value;
    this.spinnerService.spinner$.subscribe(
      (spinner) => (this.spinner = spinner),
    );

    this.addSocialLink(
      { icon: 'twitter', pack: 'fab' },
      this.business.twitter_url,
    );
    this.addSocialLink(
      { icon: 'facebook', pack: 'fab' },
      this.business.facebook_url,
    );
    this.addSocialLink(
      { icon: 'linkedin', pack: 'fab' },
      this.business.linkedin_url,
    );
    this.addSocialLink(
      { icon: 'instagram', pack: 'fab' },
      this.business.instagram_url,
    );
    this.addSocialLink('globe', this.business.web_url);
  }

  private addSocialLink(icon, url: string) {
    if (url && url.trim() !== '') {
      if (!url.startsWith('https://') && !url.startsWith('http://')) {
        url = 'https://' + url;
      }

      this.socialMenu.push({
        title: '',
        icon: icon,
        url: url,
        home: false,
        target: '_blank',
      });
    }
  }

  private destroy$: Subject<void> = new Subject<void>();
  ngOnInit() {
    this.userService.user$.subscribe((user) => {
      this.isBusiness = user && user.role === 'business';
      this.user = user;
      if (!this.user) {
        this.router.navigate(['/auth/login']);
      } else if (this.user.role === 'user') {
        this.getClient();
      } else if (this.user.role === 'business') {
        this.getOperator();
      }
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => {
        this.showHeader = isLessThanXl;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getClient() {
    this.clientService.current().subscribe((client) => {
      this.client = client;
    });
  }

  getOperator() {
    this.operatorService.current().subscribe((operator) => {
      this.operator = operator;
    });
  }

  closeSidebar() {
    this.sidebarService.collapse('menu-sidebar');
  }

  isProduction() {
    return environment.production;
  }

  redirectToWhatsapp() {
    window.open('https://wa.me/5491126281419', '_blank');
  }

  redirectToProfile() {
    if (this.user.role === 'admin') {
      this.router.navigate(['admin']);
    } else if (this.user.role === 'user') {
      this.router.navigate(['app/profile']);
    } else {
      this.router.navigate(['business/profile']);
    }
  }

  logout() {
    this.router.navigate(['auth/logout']);
  }

  selectBranch() {
    const dialogRef = this.dialogService.open(OperatorBranchSelectorComponent, {
      context: {
        operatorBranches: this.operator.operator_branches,
        selectedBranch: this.operator.activeBranch,
      },
      closeOnBackdropClick: false,
      closeOnEsc: false,
    });

    dialogRef.componentRef.instance.onSelectBranch.subscribe((operatorBranch: OperatorBranch) => {
      this.operator.activeBranch = new OperatorBranch(operatorBranch);
      this.operatorService.setOperator(this.operator);
    });

    dialogRef.onClose.subscribe(() => {
      dialogRef.componentRef.instance.onSelectBranch.unsubscribe();
    });
  }
}
