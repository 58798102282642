import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { User } from '../../_models/user.model';
import { UserService } from '../../_services/user.service';
import { ChangePasswordComponent } from '../../_dialogs/change-password/change-password.component';
import { environment } from '../../../environments/environment';
import { BusinessService } from '../../_services/business.service';
import { Business } from '../../_models/business.model';
import { NotificationComponent } from '../../_dialogs/notification/notification.component';
import { CategoryChange, CategoryChangeType } from '../../_models/category.model';
import { CalculatorComponent } from '../../_dialogs/calculator/calculator.component';
import { BusinessBranch } from '../../_models/business_branch.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { darkenHexColor, hexToRgba } from '../../@core/utils/color-utils';
import { MaxDecimalsValidator } from '../../@core/validators/MaxDecimalsValidator';

@Component({
  selector: 'puntos-user-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  editorConfigs: AngularEditorConfig[] = [];
  // * CAMBIABLE
  socialMediaLimit = 3;

  form: FormGroup;
  user: User;
  business: Business;
  newBranch: string;
  branches: BusinessBranch[];
  saving = false;
  submitted = false;
  growth: number | null = null;
  newCategory: string;
  categoryChanges: CategoryChange[] = [];

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    protected businessService: BusinessService,
    private dialogService: NbDialogService,
    private toastrService: NbToastrService) {}

  ngOnInit() {
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });
    this.editorConfigs.push({ uploadUrl: null, editable: true, showToolbar: false, toolbarHiddenButtons: [ ['fontName', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'insertImage', 'insertVideo'] ] });

    this.userService.current().subscribe((user: User) => {
      this.user = user;
      this.user.role === 'admin' || this.user.role === 'user' ? this.createAdminForm() : this.createBusinessForm();
      this.form.patchValue(this.user);

      if (this.user.business) {
        this.branches = this.user.business.branches;
      }

      if (this.user.role !== 'admin' && this.user.role !== 'business' && this.user.birthday) {
        this.form.get('birthday').disable();
      } else {
        this.form.get('birthday').enable();
      }
    });
  }

  createAdminForm() {
    this.form = this.formBuilder.group({
      first_name: ['', [<any>Validators.required]],
      last_name: [''],
      email: [{value: '', disabled: true}, [<any>Validators.required]],
      phone: [''],
      birthday: [''],
      score_amount_ratio: [''],
      money_ratio: [''],
      allow_categories: [false],
    });
  }

  createBusinessForm() {
    const limitSocialMedia = (control: FormControl, key: string) => {
      if (!this.form || !control.value) return null;
      const e = this.hasSocialMediaError();
      if (e) return { [`limit_${key}`]: true };
      else {
        // this.wipeSocialMediaError();
        return null;
      }
    };

    this.form = this.formBuilder.group({
      business: this.formBuilder.group({
        name: ['', Validators.required],
        score_amount_ratio: [0, [<any>Validators.required, Validators.min(0.000001), MaxDecimalsValidator(6)]],
        money_ratio: [0, [<any>Validators.required, Validators.min(0), MaxDecimalsValidator(6)]],
        primary_color: ['#5b53f0', [<any>Validators.required]],
        primary_negative_color: ['#ffffff', [<any>Validators.required]],
        secondary_color: ['#009ef3', [<any>Validators.required]],
        secondary_negative_color: ['#ffffff', [<any>Validators.required]],
        navbar_color: ['#5b53f0', [<any>Validators.required]],
        navbar_negative_color: ['#ffffff', [<any>Validators.required]],
        logo: ['', [<any>Validators.required]],
        logo_mini: ['', [<any>Validators.required]],
        logo_login: ['', [<any>Validators.required]],
        money_exchange_text: [''],
        whatsapp_message: ['¡Hola! Ingresá en el siguiente link y sumá {{puntos}} para llegar increíbles premios en {{comercio}} ➡️ {{link}}\n\n¡Muchas gracias!', (control: FormControl): ValidationErrors | null => {
          const forbidden = !control.value.includes('{{link}}');
          return forbidden ? { 'link': true } : null;
        }],
        twitter_url: ['', [(c: FormControl) => limitSocialMedia(c, 'twitter')]],
        facebook_url: ['', [(c: FormControl) => limitSocialMedia(c, 'facebook')]],
        linkedin_url: ['', [(c: FormControl) => limitSocialMedia(c, 'linkedin')]],
        instagram_url: ['', [(c: FormControl) => limitSocialMedia(c, 'instagram')]],
        web_url: ['', [(c: FormControl) => limitSocialMedia(c, 'web')]],
        category: [''],
        promo_popup_enabled_admin: [false],
        promo_popup_enabled: [false],
        promo_image: ['', []],
        promo_button_text: ['', []],
        promo_redirect_url: ['', []],
        show_facebook_login: [false, []],
        show_google_login: [false, []],
        send_birthday_reward: [false],
        make_client_email_mandatory_for_exchanges: [false],
        birthday_reward_points: [0, Validators.min(0)],
        purchase_emails: ['', (control: FormControl): ValidationErrors | null => {
          if (!control.value) return;
          const emails = control.value.split(',');
          const forbidden = emails.some(email => Validators.email(new FormControl(email)));
          return forbidden ? { 'emails': true } : null;
        }],
        allow_support_message: [false],
        support_message: [''],
        update_levels_score_ratio: [false],
        allow_min_amount: [false, []],
        min_amount: [0, [<any>Validators.min(0)]],
        allow_min_branches_reads: [false, []],
        min_branches_reads: [0, [<any>Validators.min(0)]],
        allow_expiration_points: [false, []],
        expiration_points_days: [0, [<any>Validators.min(0)]],
        email_tag_notification_subject: ['¡Tenés puntos para sumar en :business!', []],
        email_tag_notification_mainline: ['Recibiste puntos por tu consumo en :business.', []],
        tag_read_notification_subject: ['Sumaste :score puntos en :business', []],
        tag_read_notification_mainline: ['¡Felicitaciones! Sumaste :score puntos en tu cuenta en :business.', []],
        purchase_notification_subject: ['Canje exitoso de :product', []],
        purchase_notification_mainline: ['¡Seguí sumando puntos por tus consumos en :business para acceder a más premios increíbles!', []],
        unlocked_products_notification_subject: ['¡Ganaste un premio en :business!', []],
        unlocked_products_notification_mainline: ['Si querés ingresar a canjearlo podés hacerlo en cualquier momento pulsando el botón de abajo:', []],
        account_created_notification_subject: ['¡Completá tu registro en Tienda de Puntos!', []],
        account_created_notification_mainline: [':business te invitó a su programa de puntos ¡Felicitaciones!', []],
        levelup_notification_subject: ['¡Subiste de nivel en :business!', []],
        levelup_notification_mainline: ['¡Felicitaciones! Subiste de nivel en comercio ":business". Ahora sos nivel ":level" y vas a poder acceder nuevos beneficios.', []],
        direct_tag_whatsapp_notification: ['¡Sumaste puntos en :business!', []],
        allow_accumulate_points_rules: [false], // Todo (PREGUNTAR A JUANMA ANTES DE CAMBIAR): Si saco esto, se actualiza siempre y lo pone en false. Hay que fixearlo de alguna forma
      }),
      first_name: ['', [<any>Validators.required]],
      last_name: [''],
      fantasy_name: [''],
      email: [{value: '', disabled: true}, [<any>Validators.required]],
      phone: [''],
      birthday: [''],
      allow_categories: [false],
    });
  }

  isTuNegocio(): boolean {
    return this.businessService.getSubdomain() === 'tunegocio';
  }

  get controls() {
    return this.form.controls;
  }

  get businessControls() {
    return this.form.get('business');
  }

  hasSocialMediaError() {
    const links = [
      this.form.get('business').get('twitter_url').value,
      this.form.get('business').get('facebook_url').value,
      this.form.get('business').get('linkedin_url').value,
      this.form.get('business').get('instagram_url').value,
      this.form.get('business').get('web_url').value,
    ].filter(Boolean);

    return links.length > this.socialMediaLimit;
  }

  wipeSocialMediaError() {
    this.form.get('business').get('twitter_url').setErrors({});
    this.form.get('business').get('facebook_url').setErrors({});
    this.form.get('business').get('linkedin_url').setErrors({});
    this.form.get('business').get('instagram_url').setErrors({});
    this.form.get('business').get('web_url').setErrors({});
  }

  get businessLogoControls() {
    return this.form.get('business').get('logo');
  }

  get businessLogoLoginControls() {
    return this.form.get('business').get('logo_login');
  }

  get businessLogoMiniControls() {
    return this.form.get('business').get('logo_mini');
  }

  get businessPromoImageControls() {
    return this.form.get('business').get('promo_image');
  }

  openDescPopup() {
    const texto = 'Esta sección te permite administrar tus datos basicos.', titulo = 'Perfil';
    this.dialogService.open(NotificationComponent, {
      context: {
        status: 'white',
        title: titulo,
        message: texto,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
  }

  changePassword() {
    this.dialogService.open(ChangePasswordComponent);
  }

  isProduction(): boolean {
    return environment.production;
  }

  openPopupBirthdayRewards() {
    const texto = `
    <div style="max-width: 600px;">
    Activa esta opción para enviarle puntos a tus clientes cuando cumplan años. Estos puntos se enviaran automáticamente en su día tan especial.
    </div>
    `;
    this.dialogService.open(NotificationComponent, {
      context: {
        title: 'Cantidad de puntos a enviar por cumpleaños',
        status: 'white',
        message: texto,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
  }

  openPopupPuntosPesos() {
    const texto = `
    <div style="max-width: 600px;">
    Indica en este campo la cantidad de puntos que entregas por cada peso que gaste tu cliente en tu comercio.
    </br>
    </br>
    ¿Aumentaste tus precios? Puedes reducir esta variable en la misma proporción para que tus clientes no lleguen más fácilmente
    a tus premios. Por ejemplo, si entregabas 10 puntos por peso y aumentaste un 10%, ahora puedes entregar 9 puntos por peso.
    </div>
    `;
    this.dialogService.open(NotificationComponent, {
      context: {
        title: 'Puntos a entregar por $ cobrado',
        status: 'white',
        message: texto,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
  }

  openPopupLevelsUpdate() {
    const texto = `
    <div style="max-width: 600px;">
    Si se selecciona este campo, las modificaciones establecidas a los puntos a entregar por $ cobrado se aplicarán también para los niveles creados.
    </br>
    </br>
    La actualización de la relación $/puntos en cada nivel, corresponderá a una reducción o aumento porcentual, inversamente equivalente a la modificación que se realice en la relación $/puntos general de la empresa.
    </br>
    </br>
    <strong>IMPORTANTE: El cambio en la relación para los niveles solo funcionará si se utiliza la calculadora.</strong>
    </div>
    `;
    this.dialogService.open(NotificationComponent, {
      context: {
        title: 'Modificar relación $/puntos en niveles',
        status: 'white',
        message: texto,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
  }

  openPopupMoneyRatio() {
    const texto = `
    <div style="max-width: 600px;"> Este campo indica cuanto Dinero se puede canjear por cada punto acumulado. </div>
    `;
    this.dialogService.open(NotificationComponent, {
      context: {
        title: 'Canje de Puntos por Dinero',
        status: 'white',
        message: texto,
        showButton: false,
      },
      closeOnBackdropClick: true,
      closeOnEsc: true,
    });
  }

  delete(idx) {
    const oldChange = this.categoryChanges.findIndex(c => c.name === this.user.business.product_categories[idx].name);
    if (oldChange === -1) {
      this.categoryChanges.push({
        type: CategoryChangeType.Remove,
        id: this.user.business.product_categories[idx].id,
        name: this.user.business.product_categories[idx].name,
      });
    } else {
      this.categoryChanges.splice(oldChange, 1);
    }
    this.user.business.product_categories.splice(idx, 1);
    this.form.markAsDirty();
  }

  addCategory() {
    const oldChange = this.categoryChanges.findIndex(c => c.name === this.newCategory);
    if (oldChange === -1) {
      this.categoryChanges.push({
        type: CategoryChangeType.Add,
        name: this.newCategory,
      });
    } else {
      this.categoryChanges.splice(oldChange, 1);
    }
    this.user.business.product_categories.push({
      name: this.newCategory,
    });

    this.newCategory = '';
    this.form.markAsDirty();
  }

  addBranch() {
    if (this.branches == null) this.branches = [];
    if (!this.newBranch || this.newBranch.length === 0) {
      this.toastrService.danger('No se puede crear una sucursal sin nombre.', 'Ups!');
      return;
    }
    if (this.branches.find(b => b.name === this.newBranch)) {
      this.toastrService.danger('No se pueden crear 2 o mas sucursales con el mismo nombre.', 'Ups!');
      return;
    }
    this.branches.push(new BusinessBranch(this.newBranch));
    this.newBranch = '';
    this.form.markAsDirty();
  }

  deleteBranch(branch: BusinessBranch) {
    branch.state = 'deleted';
    this.form.markAsDirty();
  }

  save() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    this.saving = true;

    if (this.user.role === 'business') {
      this.business = new Business(this.form.get('business').value);
      this.business.product_categories = this.user.business.product_categories;
    }

    this.userService.save(Object.assign(this.user, new User(this.form.value))).subscribe(() => {
      if ( this.user.role === 'business' ) {
        this.business.id = this.user.business_id;
        const data = {
          ...this.business,
          score_amount_ratio: `${this.businessControls.get('score_amount_ratio').value}`,
          growth: this.growth,
          product_categories: this.business.product_categories,
          categoryChanges: this.categoryChanges,
          branches: this.branches,
        };

        this.businessService.save(data).subscribe(() => {
          this.toastrService.success('Los datos fueron cambiados con éxito.', '¡Bien!');
          this.userService.reloadUser().subscribe((user) => {
            this.businessService.reloadBusiness().subscribe((business) => {
              this.business = new Business(business);
              if (document.getElementById('logo-header')) {
                document.getElementById('logo-header').setAttribute('src', this.business.logoSizes.header );
              }

              document.documentElement.style.setProperty('--primary-color', this.business.primary_color);
              document.documentElement.style.setProperty('--primary-light-color', hexToRgba(this.business.primary_color || '#db38b7', 0.1));
              document.documentElement.style.setProperty('--primary-dark-color', darkenHexColor(this.business.primary_color || '#db38b7', 20));
              document.documentElement.style.setProperty('--primary-color', this.business.primary_color);
              document.documentElement.style.setProperty('--primary-negative-color', this.business.primary_negative_color);
              document.documentElement.style.setProperty('--secondary-color', this.business.secondary_color);
              document.documentElement.style.setProperty('--secondary-negative-color', this.business.secondary_negative_color);
              document.documentElement.style.setProperty('--navbar-color', this.business.navbar_color);
              document.documentElement.style.setProperty('--navbar-negative-color', this.business.navbar_negative_color);

              this.saving = false;
              // FIXME window.location.reload();
            });
          });
        }, err => {
          this.saving = false;
        });
      } else {
        this.toastrService.success('Los datos fueron cambiados con éxito.', '¡Bien!');
          this.userService.reloadUser().subscribe((user) => {
            this.saving = false;
            window.location.reload();
          });
      }
    }, (err) => {
      this.saving = false;
    });

  }

  calculator() {
    const calculatorBusiness = this.business ? this.business : this.user.business;
    const score_amount_ratio = this.form.get('business').get('score_amount_ratio').value;

    const dialogRef = this.dialogService.open(CalculatorComponent, { context: { score_amount_ratio: score_amount_ratio, business: calculatorBusiness } });

    dialogRef.componentRef.instance.onSetNewScoreAmountRatio.subscribe((new_score_amount_ratio: number) => {
      this.form.get('business').get('score_amount_ratio').setValue(new_score_amount_ratio);
      this.form.get('business').get('score_amount_ratio').markAsTouched();
    });

    dialogRef.componentRef.instance.onSetGrowthPercentage.subscribe((growth: number) => {
      this.growth = growth;
    });

    dialogRef.onClose.subscribe(() => {
      dialogRef.componentRef.instance.onSetNewScoreAmountRatio.unsubscribe();
      dialogRef.componentRef.instance.onSetGrowthPercentage.unsubscribe();
    });
  }

  onFormValueChange(key, value) {
    this.form.get('business').get(key).setValue(value);
    this.form.get('business').get(key).markAsDirty();
  }

  onEditorFocus(index) {
    this.editorConfigs[index].showToolbar = true;
  }

  onEditorBlur(index) {
    this.editorConfigs[index].showToolbar = false;
  }

}
