<nb-card class="dialog">
  <div class="notification-container" style="overflow: overlay;">
    <div class="notification-header">
      <p><strong>{{ title }}</strong></p>
    </div>
    <div class="notification-item">
      <div [innerHtml]="messageHtml"></div>
    </div>
  </div>

  <button (click)="close()" class="close close-button">
    <nb-icon icon="Chevron_Left" class="detail-icon"></nb-icon>
  </button>
</nb-card>
