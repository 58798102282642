import { NbAuthService } from '@nebular/auth';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user.model';
import { ReplaySubject, Observable } from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@nebular/auth";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
export class UserService {
    constructor(authService, http, router) {
        this.authService = authService;
        this.http = http;
        this.router = router;
        this.user = new User;
        this._user = new ReplaySubject(1);
        this.user$ = this._user.asObservable();
        this.search = (q, m, b) => {
            const params = { q, m };
            if (b) {
                params.b = b;
            }
            return this.http.get('users/search', { params: params });
        };
        this.authService
            .onAuthenticationChange()
            .subscribe((isAuthenticated) => {
            if (!isAuthenticated) {
                this.user = null;
                this._user.next(null);
                return;
            }
            this.reloadUser().subscribe((user) => {
                const isClientPreview = sessionStorage.getItem('isClientPreview');
                if (isClientPreview === 'true') {
                    this.router.navigate(['/app/score']);
                }
                else if (isClientPreview === 'false') {
                    sessionStorage.removeItem('isClientPreview');
                    this.router.navigate(['/business/tags/operate']);
                    location.reload();
                }
            });
        });
    }
    one(id) {
        return new User({ id: +id });
    }
    get(user) {
        return this.http.get(`users/${user.id}`)
            .pipe(map((response) => new User(response)));
    }
    getUser(force = false) {
        if (localStorage.getItem('user') === null || force || sessionStorage.getItem('isClientPreview')) {
            return this.reloadUser();
        }
        else {
            this.user = new User(JSON.parse(localStorage.getItem('user')));
            this.user.role = sessionStorage.getItem('isClientPreview') ? 'user' : this.user.role;
            this._user.next(this.user);
            return Observable.of(this.user);
        }
    }
    reloadUser() {
        return this.http.get('user')
            .pipe(map((response) => {
            if (response.success) {
                return new User(Object.assign({}, response.data, { role: sessionStorage.getItem('isClientPreview') ? 'user' : response.data.role }));
            }
            else {
                this.router.navigate(['auth/logout']);
                return;
            }
        }))
            .pipe(map((user) => {
            this.user = user;
            this._user.next(this.user);
            return this.user;
        }));
    }
    retrieveToken(params) {
        return this.http.get('auth/validateToken', { params: params })
            .pipe(map((response) => {
            response.token = params.token;
            return response;
        }));
    }
    current() {
        return this.user$
            .filter(user => user !== null);
    }
    clear() {
        this.user = null;
        this._user.next(this.user);
        localStorage.removeItem('user');
    }
    requestResetPassword(data) {
        return this.http.post('oauth/password/email', data);
    }
    // Todo: Ver si esto se esta usando
    /*checkPassword(token) {
      return this.http.post('oauth/password/reset', { token });
    }
  
    resetPassword(token, newPassword) {
      return this.http.post('oauth/password/reset', { token });
    }*/
    resetPassword(password, password_confirmation) {
        return this.http.put('users/password', { password, password_confirmation });
    }
    resendVerification(id, business_id) {
        return this.http.post(`users/resendVerification/${id}`, { id, business_id });
    }
    activateEmail(operator) {
        return this.http.post(`users/activateEmail/${operator.id}`, {});
    }
    save(user, send_notification = false) {
        const method = user.id ? 'put' : 'post';
        let url = 'users';
        if (method === 'put') {
            url += '/' + user.id;
        }
        return this.http.request(method, url, { body: Object.assign({}, user, { send_notification }) });
    }
    unsubscribe(email, type) {
        return this.http.get('notifications/unsubscribe', { params: { email, type } });
    }
    loadUsers(id, file, sendNotification = false) {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('sendNotification', sendNotification.toString());
        return this.http.post(`users/loadsheet/${id}`, formData);
    }
    updateEmail(userId, email) {
        return this.http.put(`users/${userId}/email`, { email });
    }
    updateDni(userId, dni) {
        return this.http.put(`users/${userId}/dni`, { dni });
    }
}
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.NbAuthService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.Router)); }, token: UserService, providedIn: "root" });
