import { User } from './user.model';
import { Business } from './business.model';
import { OperatorBranch } from './operator_branch.model';

export class Operator {
  id?: number;
  role: 'admin' | 'operator';

  business: Business;
  business_id: number;

  user: User;
  user_id: number;

  operator_branches: OperatorBranch[] = [];
  activeBranch: OperatorBranch = null;
  public constructor(init?: any) {
    Object.assign(this, init);
    if (this.operator_branches.length > 0) {
      this.activeBranch = new OperatorBranch(this.operator_branches[0]);
    }
  }

  get permissions() {
    return this.activeBranch && this.activeBranch.permission_group && this.activeBranch.permission_group.permissions ? this.activeBranch.permission_group.permissions : null;
  }

  get activeBranchAdmin() {
    return this.activeBranch && !this.activeBranch.permission_group;
  }

  canAccessTo(key: string) {
    const variables = {
      exchange: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.exchange.can_view),
      operate: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.operate.can_view),
      clients: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.clients.can_view),
      history: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.history.can_view),
      history_provider: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.history_provider.can_view),
      products: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.products.can_view),
      products_shop: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.products_shop.can_view),
      statistics: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.statistics.can_view),
      validate: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.validate.can_view),
      integrations: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.integrations.can_view),
      levels: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.levels.can_view),
      surveys: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.surveys.can_view),
      associated_frauds: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.associated_frauds.can_view),
      possible_frauds: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.possible_frauds.can_view),
      point_rules: this.role === 'admin' || this.activeBranchAdmin ? true : (this.permissions && !!this.permissions.point_rules.can_view),
    };

    return variables[key];

  }

  get isAdmin() {
    return this.role === 'admin';
  }

  get name() {
    if (!this.user) {
      return '';
    }
    const firstName = this.user.first_name ? this.user.first_name : '';
    const email = this.user.email ? this.user.email : '';
    const dni = this.user.dni ? this.user.dni : '';
    const lastName = this.user.last_name ? this.user.last_name : '';
    if (firstName.length > 0 || lastName.length > 0) {
      return `${firstName} ${lastName}`;
    } else if (email.length > 0) {
      return `${email}`;
    }

    return `${dni}`;
  }

  showDescription = () => {
    if (!this.user) {
      return '';
    }
    const firstName = this.user.first_name ? this.user.first_name : '';
    const email = this.user.email ? this.user.email : '';
    const dni = this.user.dni ? this.user.dni : '';
    const lastName = this.user.last_name ? this.user.last_name : '';
    if (firstName.length > 0 || lastName.length > 0) {
      return `${firstName} ${lastName}`;
    } else if (email.length > 0) {
      return `${email}`;
    }

    return `${dni}`;
  }
}
