<nb-card class="dialog" [status]="status">
  <nb-card-header>Eliminar plan</nb-card-header>
  <nb-card-body>
    <div class="row form-group" *ngIf="plan?.businesses?.length > 0">
      <label class="label col-sm-3 col-form-label">
        Plan a transferir
      </label>
      <div class="col-sm-9">
        <puntos-filter 
          label="Plan" 
          [list]="search"
          (onSelect)="select('plan', $event)"
          [selected]="filters?.plan">
        </puntos-filter>
      </div>
    </div>

    <p [class.mt-5]="plan?.businesses?.length > 0">¿Esta seguro de que desea eliminar el plan?</p>

   
  </nb-card-body>
  <nb-card-footer>
      <button nbButton (click)="accept()" [status]="deleteButtonStatus" class="float-right">Eliminar</button>
      <button nbButton (click)="close()" [status]="acceptButtonStatus" class="float-left">Cerrar</button>
  </nb-card-footer>
</nb-card>

