<nb-card class="dialog">
  <nb-card-header>
    Filtrar por
    <button (click)="close()" class="close close-button">
      <nb-icon icon="Close_MD"></nb-icon>
    </button>
  </nb-card-header>

  <nb-card-body>
    <div class="filter-section">
      <label>Coste de puntos</label>
      <div class="points-range">
        <div class="points">
          <nb-icon icon="logo" pack="puntos" class="mr-2"></nb-icon>
          <input nbInput type="number" [(ngModel)]="points">
          <!--<nb-icon icon="Edit_Pencil_01"></nb-icon>-->
        </div>
        <div class="points">
          <nb-icon icon="logo" pack="puntos" class="mr-2"></nb-icon>
          <input nbInput type="number" [(ngModel)]="highPoints">
          <!--<nb-icon icon="Edit_Pencil_01"></nb-icon>-->
        </div>
      </div>
      <ngx-slider [(value)]="points" [(highValue)]="highPoints" [options]="options"></ngx-slider>
    </div>

    <div class="filter-section">
      <label>Elegir categorías</label>
      <div *ngFor="let category of categories" >
        <button nbButton 
                class="category-button outlined" 
                [ngClass]="{ selected: selectedCategory?.id === category.id }" 
                (click)="selectCategory(category)">
          {{ category.name }}
        </button>      
      </div>
    </div>

    <div class="filter-section">
      <label>Ordenar alfabéticamente</label>
      <nb-toggle
        [(ngModel)]="alphabetical"
        [checked]="alphabetical"
        size="small">
      </nb-toggle>
    </div>

    <div class="filter-section">
      <label>Ordenar por</label>
      <nb-radio-group [(ngModel)]="sortOrder">
        <nb-radio *ngFor="let option of sortOptions" [value]="option.value">{{ option.label }}</nb-radio>
      </nb-radio-group>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <button nbButton fullWidth status="primary" (click)="applyFilters()" class="puntos-button filled">Aplicar cambios</button>
  </nb-card-footer>
</nb-card>
