<nb-card class="dialog" [status]="status">
  <nb-card-header>Seleccione un nivel</nb-card-header>
  <nb-card-body>
    <nb-select id="inputType" [(ngModel)]="selectedLevel">
      <nb-option *ngFor="let level of levels" [value]="level">{{
        level.name
      }}</nb-option>
    </nb-select>
    <nb-alert *ngIf="!levels?.length" status="info"
      >No existe ningun nivel creado.</nb-alert
    >
  </nb-card-body>
  <nb-card-footer>
    <button
      nbButton
      (click)="cancel()"
      [status]="deleteButtonStatus"
      class="puntos-button outlined float-left"
    >
      Cancelar
    </button>
    <button
      nbButton
      (click)="accept()"
      [status]="acceptButtonStatus"
      class="puntos-button filled float-right"
    >
      Actualizar
    </button>
  </nb-card-footer>
</nb-card>
