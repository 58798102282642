<nb-card class="dialog">
  <h2>Notificaciones</h2>
  <h3>Aquí encontrarás novedades, ideas, tutoriales y más.</h3>

  <div class="notifications-container">
    <div class="notification-header">
      <p><nb-icon icon="inbox" style="margin-right: 4px;"></nb-icon><strong>Bandeja de entrada</strong></p>
    </div>
   
  </div>

  <button (click)="close()" class="close close-button"><nb-icon icon="Close_MD"></nb-icon></button>

</nb-card>
