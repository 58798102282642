import { User } from './user.model';
import { Product } from './product.model';
import { Business } from './business.model';
import * as moment from 'moment';
import { BusinessBranch } from './business_branch.model';
import { Operator } from './operator.model';
import { ProductOffer } from './product_offer.model';
import { Client } from './client.model';

export class Purchase {
  id: number;
  // user_id: number;
  client_id: number;
  payment_id: number;
  product_id: number;
  business_id: number;
  product_business_id: number;
  product_offer_id: number;
  code: string;
  qr: string;
  score: number;
  amount: number = 0;
  money_amount: number;
  status: string;
  description: string;
  created_at: moment.Moment;
  updated_at: moment.Moment;
  operator: Operator;


  type = 'purchase';

  user?: User;
  client?: Client;
  product?: Product;
  branch?: BusinessBranch;
  business: Business;
  productOffer?: ProductOffer;

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);
      if (this.client) {
        this.client = new Client(this.client);
      }
      if (this.user) {
        this.user = new User(this.user);
      }
      if (init.product) {
        this.product = new Product(init.product);
      }
      if (this.created_at) {
        this.created_at = moment(this.created_at);
      }
      if (this.updated_at) {
        this.updated_at = moment(this.updated_at);
      }
    }
  }
}
