<nb-card class="dialog" [status]="status">
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    <nb-calendar-range [(range)]="selectedRange"></nb-calendar-range>
  </nb-card-body>
  <nb-card-footer>
    <button
      nbButton
      (click)="accept()"
      [status]="acceptButtonStatus"
      class="puntos-button filled float-right"
    >
      Aceptar
    </button>
  </nb-card-footer>
</nb-card>
