import { Business } from './business.model';
import { Level } from './level.model';
import { Product } from './product.model';

export enum ProductOfferType {
  Redeem = 0,
  Marketplace = 1,
}

export class ProductOffer {
  id: number;

  value: number = 0;

  show_discount: boolean = false;
  discount: number = 0;

  expiration_date: Date = null;
  type: ProductOfferType = ProductOfferType.Redeem;

  business: Business;
  business_id: number;

  level: Level;
  level_id: number;

  product: Product;

  created_at: Date = null;
  updated_at: Date = null;
  deleted_at: Date = null;

  public constructor(init?: any) {
    if (typeof init === 'object') {
      Object.assign(this, init);

      const aux = parseFloat(this.value.toString()).toFixed(2);
      this.value = parseFloat(aux);
      if (init.product) {
        this.product = new Product(init.product);
      }
    }
  }

  showDescription() {
    if (!this.product) {
      return `¡Ups! Este premio no se encuentra disponible (#${this.id})`;
    }
    let text = `${this.product.name} (Puntos necesarios: ${this.value.toString().split('.')[0]})`;
    if (this.level) {
      text += ' - ' + this.level.name;
    } else {
      text += ' - Todos los niveles';
    }
    return text;
  }

}
