import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbSelectModule,
  NbSpinnerModule,
  NbRadioModule,
  NbTabsetModule,
  NbUserModule,
  NbProgressBarModule,
  NbListModule,
  NbMenuModule,
  NbTooltipModule,
  NbAccordionModule,
  NbPopoverModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbToggleModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbSecurityModule } from '@nebular/security';
import { ProductClusterComponent } from './product-cluster/product-cluster.component';
import { NotificationComponent } from '../_dialogs/notification/notification.component';
import { HistoryPurchaseComponent } from '../pages/history/history-purchase/history-purchase.component';
import { HistoryReadComponent } from '../pages/history/history-read/history-read.component';
import { MenuComponent } from '../_dialogs/menu/menu.component';
import { ConfirmComponent } from '../_dialogs/confirm/confirm.component';
import { FilterComponent } from './filter/filter.component';
import { InputImageModule } from './input-image/input-image.module';
import { ProfileComponent } from './profile/profile.component';
import { ChangePasswordComponent } from '../_dialogs/change-password/change-password.component';
import { BusinessMoneyComponent } from './business-money/business-money.component';
import { LoadFileComponent } from '../_dialogs/load-file/load-file.component';
import { ProductCategorySelectorComponent } from '../pages/products/product-category-selector/product-category-selector.component';
import { AddEmailComponent } from '../_dialogs/add-email/add-email.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ComunnicationComponent } from '../_dialogs/comunnication/comunnication.component';
import { CommunicationDetailComponent } from '../_dialogs/communication-detail/communication-detail.component';
import { NotificationWithClickComponent } from '../_dialogs/notification-with-click/notification-with-click.component';
import { DateFormatPipe, NumberWithCommasPipe } from '../@theme/pipes';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConfirmSubstractComponent } from '../_dialogs/confirm-substract/confirm-substract.component';
import { AddProductToPlanComponent } from '../_dialogs/add-product-to-plan/add-product-to-plan.component';
import { UsersListComponent } from '../_dialogs/users-list/users-list.component';
import { BranchesListComponent } from '../_dialogs/branches-list/branches-list.component';
import { BranchSelectorComponent } from './branch-selector/branch-selector.component';
import { BusinessesListComponent } from '../_dialogs/businesses-list/businesses-list.component';
import { DeletePlanComponent } from '../_dialogs/delete-plan/delete-plan.component';
import { CalculatorComponent } from '../_dialogs/calculator/calculator.component';
import { IntegrationSelectorComponent } from '../_dialogs/integration-selector/integration-selector.component';
import { UpdateClientLevelComponent } from '../_dialogs/update-client-level/update-client-level.component';
import { DragonFishAuthComponent } from '../_dialogs/dragon-fish-auth/dragon-fish-auth.component';
import { ProductsListComponent } from '../_dialogs/products-list/products-list.component';
import { ShowQrComponent } from '../_dialogs/show-qr/show-qr.component';
import { PhoneFormComponent } from '../_dialogs/phone-form/phone-form.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TestConnectionsComponent } from '../_dialogs/test-connections/test-connections.component';
import { DateRangeSelectorComponent } from '../_dialogs/date-range-selector/date-range-selector.component';
import { LevelClusterComponent } from './level-cluster/level-cluster.component';
import { EmptyScreenComponent } from './empty-screen/empty-screen.component';
import { TdpIconComponent } from './tdp-icon/tdp-icon.component';
import { NextProductClusterComponent } from './next-product-cluster/next-product-cluster.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { ProductsFilterComponent } from '../_dialogs/products-filter/products-filter.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OperatorBranchSelectorComponent } from '../_dialogs/operator-branch-selector/operator-branch-selector.component';
import { TdpPaginationComponent } from './tdp-pagination/tdp-pagination.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CreateClientComponent } from './create-client/create-client.component';
import { ConfirmExchangePointsMoneyComponent } from '../_dialogs/confirm-exchange-points-money/confirm-exchange-points-money.component';

const SHARED_MODULES = [
  NbButtonModule,
  NbIconModule,
  NbCardModule,
  NbLayoutModule,
  NbAlertModule,
  NbCheckboxModule,
  NbInputModule,
  NbActionsModule,
  NbPopoverModule,
  NbUserModule,
  NbSpinnerModule,
  NbSelectModule,
  NbRadioModule,
  FormsModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NbSecurityModule,
  NbProgressBarModule,
  NbListModule,
  NbMenuModule,
  NbTooltipModule,
  InputImageModule,
  NbAccordionModule,
  FontAwesomeModule,
  NbTabsetModule,
  ColorPickerModule,
  AngularEditorModule,
  NgxIntlTelInputModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbToggleModule,
  NgxSliderModule,
  NgxPaginationModule,
];

const SHARED_COMPONENTS = [
  BusinessMoneyComponent,
  ProductClusterComponent,
  NotificationComponent,
  ComunnicationComponent,
  CommunicationDetailComponent,
  MenuComponent,
  ConfirmComponent,
  ChangePasswordComponent,
  HistoryPurchaseComponent,
  HistoryReadComponent,
  FilterComponent,
  LoadFileComponent,
  ProductCategorySelectorComponent,
  AddEmailComponent,
  NotificationWithClickComponent,
  ConfirmSubstractComponent,
  AddProductToPlanComponent,
  NumberWithCommasPipe,
  DateFormatPipe,
  UsersListComponent,
  BranchesListComponent,
  BranchSelectorComponent,
  CreateClientComponent,
  BusinessesListComponent,
  DeletePlanComponent,
  CalculatorComponent,
  IntegrationSelectorComponent,
  UpdateClientLevelComponent,
  DragonFishAuthComponent,
  ProductsListComponent,
  ShowQrComponent,
  PhoneFormComponent,
  TestConnectionsComponent,
  DateRangeSelectorComponent,
  LevelClusterComponent,
  EmptyScreenComponent,
  TdpIconComponent,
  NextProductClusterComponent,
  SearchInputComponent,
  ProductsFilterComponent,
  OperatorBranchSelectorComponent,
  TdpPaginationComponent,
  ProfileComponent,
  ConfirmExchangePointsMoneyComponent,
];

@NgModule({
  declarations: [
    ...SHARED_COMPONENTS,
  ],
  imports: [
    CommonModule,
    ...SHARED_MODULES,
  ],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
  ],
  entryComponents: [
    MenuComponent,
    NotificationComponent,
    PhoneFormComponent,
    ConfirmComponent,
    LoadFileComponent,
    ChangePasswordComponent,
    UsersListComponent,
    BranchesListComponent,
    BranchSelectorComponent,
    AddEmailComponent,
    ComunnicationComponent,
    CommunicationDetailComponent,
    NotificationWithClickComponent,
    ConfirmSubstractComponent,
    AddProductToPlanComponent,
    CreateClientComponent,
    BusinessesListComponent,
    DeletePlanComponent,
    CalculatorComponent,
    IntegrationSelectorComponent,
    UpdateClientLevelComponent,
    DragonFishAuthComponent,
    ProductsListComponent,
    ShowQrComponent,
    TestConnectionsComponent,
    DateRangeSelectorComponent,
    TdpIconComponent,
    NextProductClusterComponent,
    ProductsFilterComponent,
    OperatorBranchSelectorComponent,
    TdpPaginationComponent,
    ConfirmExchangePointsMoneyComponent,
  ],
})
export class SharedModule { }
